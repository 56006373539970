.form_outer {
  position: relative;

  @media (max-width: 425px) {
    width: 100%;
  }

  @media (min-width: 425px) and (max-width: 768px) {
    width: 60%;
  }
}

.form_inner {
  height: 55px;

  @media (max-width: 768px) {
    height: 40px;
  }

  input {
    border-radius: 5px;
    font-size: 16px;
    color: #1d1d1f;
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding-left: 50px;

    @media (max-width: 768px) {
      background-color: #eee;
      border-radius: 15px;

      &:focus {
        border: 1px solid #d1d1d1;
      }
    }
  }
}

.form_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;

  width: 50px;
  height: 100%;

  svg {
    color: #767676;
  }
}

.search_icon {
  left: 0;

  svg {
    width: 24px;
    height: 24px;
  }
}

.clear_icon {
  right: 0;

  svg {
    width: 18px;
    height: 18px;
  }
}

.navbar_form_outer {
  width: 60%;

  @media (max-width: 425px) {
    width: 100%;
  }
}

.navbar_form_inner {
  height: 40px;

  input {
    background-color: #eee;
    border: 1px solid #fff;
    border-radius: 15px;
    transition: all .3s ease;

    &:focus {
      background-color: #fff;
      border-color: #d1d1d1;
    }

    &:hover {
      border: 1px solid #d1d1d1;
    }
  }
}

.panel {
  display: none;
  color: #1d1d1f;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: calc(100% - -4px);
  left: 0;
  right: 0;
  width: 100%;
  padding: 8px 0;
  z-index: 95;
}

.show {
  border: 1px solid #d1d1d1;
  display: block;
  z-index: 95;
}

.panel_items {
  display: grid;
  gap: 10px;
  padding: 8px 16px;
  z-index: 95;
}

.panel_title {
  display: flex;
  align-items: baseline;
  gap: 5px;
  z-index: 95;

  span {
    font-weight: 500;
  }

  button {
    color: #767676;
    background: none;
    transition: color .3s ease-out;

    &:hover {
      color: #1d1d1f;
    }
  }
};

.recent {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.recent_button {
  color: #1d1d1f;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 8px 16px;
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }
}