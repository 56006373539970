.masonry_block {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
  align-items: start;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.masonry_block_modal {
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.masonry_column {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 15px;
}

.masonry_column_modal {
  gap: 10px
}