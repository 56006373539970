.topics_wrapper {
  background-color: #ffbb38;
  box-shadow: 0 4px 12px #00000014, 0 0 1px #0100001a;
  position: sticky;
  top: 60px;
  left: 0;
  padding: 0 20px;
  z-index: 29;

  @media (max-width: 768px) {
    padding: 0 12px;
  }
}

.topic {
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 24px;
  }

  a {
    color: #003679;
    display: flex;
    align-items: center;
    font-size: medium;
    padding: 3px;
    height: 55px;
    transition: color .2s ease;

    &:hover {
        color: rgb(0, 0, 16);
        font-size: 17px;
       
    }
  }
}

.active_topic {
  color: #1d1d1f !important;
  box-shadow: inset 0 -2px #111;
}

.arrow_button {
  color: #767676;
  display: flex;
  align-items: center;
  width: 30px;

  &:hover {
    color: #1d1d1f;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.arrow_left {
  justify-content: flex-start;
}

.arrow_right {
  justify-content: flex-end;
}