.header_outer {
  color: #fff;
  position: relative;

  @media (max-width: 768px) {
    padding: 0 12px;
  }
}
.user_outer {
  padding: 60px 0;
  box-shadow: inset 0 -2px #212122;

  @media(max-width: 768px) {
    padding: 40px 20px;
  }
}
.user_inner {
  display: flex;
  justify-content: center;
  gap: 50px;

  @media(max-width: 768px) {
    flex-direction: column;
    gap: 25px
  }
}
.header_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 600px;

  @media (max-width: 768px) {
    height: 300px;
  }
}

.header_bg {
  background-color: #1d1d1f;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  z-index: -1;

  &::after {
    background: linear-gradient(90deg, rgba(29, 29, 31, 0.5) 0%, rgba(29, 29, 31, 0.5) 50%, rgba(29, 29, 31, 0.5) 100%);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  span {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header_content {
  display: grid;
  width: 60%;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  h1 {
    font-size: 70px;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 24px;
  justify-content: center;
  text-align: center;
    @media (max-width: 768px) {
      font-size: 15px;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
.buttonnya{
  background-color: #003679;
    color: white;
    font-size: 25px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}
.header_user {
  display: flex;
  gap: 5px;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 20px 20px;
  z-index: 5;

  span {
    color: rgba(255, 255, 255, 0.5);
  }

  a, button {
    color: #FFFC;
    transition: color .2s ease;

    &:hover {
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 12px 20px 12px;
  }
}