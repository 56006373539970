.images {
  padding: 25px 0;

  h1 {
    font-size: 48px;
    text-transform: capitalize;
    padding: 20px 0;

    @media (max-width: 768px) {
      font-size: 28px;
      padding: 20px 12px;
    }
  }
}

.images_padding {
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.image_wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover {
    .user_wrapper {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.user_wrapper {
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 10;
  cursor: pointer;

  @media (max-width: 768px) {
    background: none;
    display: block;
    position: static;
    height: auto;
    padding: 12px;
    opacity: 1;
    visibility: visible;
    cursor: default;
  }

  a {
    display: inline-flex;
    align-items: center;

    @media (max-width: 768px) {
      cursor: pointer;
    }

    h3 {
      color: #fff;
      font-weight: 400;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      @media (max-width: 768px) {
        color: #1d1d1f;
        pointer-events: none;
        opacity: 1;
      }
    }
  }
}

.user_image {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;

  img {
    border-radius: 50%;
  }
}

.image {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    height: auto !important;
  }
}