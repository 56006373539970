.modal_outer {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: auto;
}

.modal_inner {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 50px;

  @media (min-width: 1400px) {
    padding-left: 120px;
    padding-right: 120px;
  }

  @media (min-width: 768px) and (max-width: 1399px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.close_button {
  color: #fff;
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  padding: 8px;

  svg {
    width: 25px;
    height: 25px;
  }

  @media (max-width: 768px) {
    position: static;
  }
}

.modal_content {
  height: 100%;
}

.filter_modal {
  padding: 50px;
  height: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
}