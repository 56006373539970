.user_outer {
  padding: 60px 0;

  @media(max-width: 768px) {
    padding: 40px 20px;
  }
}

.user_inner {
  display: flex;
  justify-content: center;
  gap: 50px;

  @media(max-width: 768px) {
    flex-direction: column;
    gap: 25px
  }
}

.user_image_wrapper {
  @media(min-width: 768px) {
    width: 33.33333%;
  }
}

.user_image {
  width: 150px;
  height: 150px;

  @media(min-width: 768px) {
    margin-left: auto;
  }

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.user_info {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media(min-width: 768px) {
    width: 66.66667%;
  }

  .name {
    font-size: 40px;
    font-weight: 700;

    @media(max-width: 768px) {
      font-size: 20px;
    }
  }

  .bio {
    @media(min-width: 768px) {
      max-width: 70%;
    }
  }
}


.user_items {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.user_item {
  color: #767676;
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.available {
  color: #007fff !important;
}

.user_interest {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.user_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  a {
    color: #767676;
    background-color: #eee;
    border-radius: 2px;
    text-transform: capitalize;
    padding: 5px 8px;
    transition: all .3s ease;

    &:hover {
      color: #1d1d1f;
      background-color: #e1e1e1;
    }
  }
}

.tabs_outer {
  box-shadow: inset 0 -1px #d1d1d1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.tabs_inner {
  display: flex;
  gap: 32px;
}

.tab {
  font-weight: 400;
  color: #1d1d1f;
  box-shadow: inset 0 -2px #1d1d1f;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 60px;
  transition: color .2s ease;

  &:hover {
    color: #1d1d1f;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}