.modal {
  background: #fff;
  border-radius: 4px;
  display: grid;

  @media (max-width: 768px) {
    border-radius: 0;
    margin: 0;
    min-height: calc(100vh - 50px)
  }
}

.modal_header {
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  padding: 15px 20px;
  z-index: 20;

  @media (max-width: 768px) {
    border-radius: 0;
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 8px;

  .photo {
    min-width: 32px;
    height: 32px;

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  a {
    font-size: 15px;
    color: #1d1d1f;
  }
}


.download {
  height: 32px;

  a {
    color: #fff;
    background-color: #3cb46e;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    transition: all .3s ease;

    &:hover {
      background-color: #37a866;
    }
  }
}

.disable_button {
  opacity: 0.5;
  pointer-events: none;
  cursor: wait;
}

.modal_body {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1050px;
  height: 500px;
  margin: 0 auto;

  span {
    height: 100%;
  }

  img {
    cursor: zoom-in;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 768px) {
      cursor: default;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 0 20px;
  }
}

.full_image {
  width: 100%;
  max-width: none;
  height: auto;

  span {
    width: 100%;
  }

  img {
    cursor: zoom-out;
  }
}

.modal_footer {
  display: grid;
  row-gap: 25px;
  padding: 20px;
}

.first_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, 160px));
  row-gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 425px) {
    flex-direction: column;
  }
}

.first_row_item {
  display: grid;
  row-gap: 5px;

  h3 {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
  }

  span {
    font-size: 14px;
  }
}

.second_row {
  display: grid;
  align-items: center;
  row-gap: 10px;
}

.second_row_item {
  color: #767676;
  display: flex;
  column-gap: 8px;

  svg {
    color: #767676;
    width: 16px;
    height: 16px;
  }
}

.related_images {
  margin: 50px 0 30px 0;

  h2 {
    font-size: 18px;
    font-weight: 400;
    padding: 0 20px;

    @media (min-width: 768px) {
      max-width: 1300px;
      margin: 0 auto;
    }
  }
}