.tabs_wrapper {
  position: sticky;
  top: 60px;
  left: 0;
  z-index: 19;
}

.collections_outer {
  padding: 25px 0;

  h1 {
    font-size: 48px;
    text-transform: capitalize;
    padding: 20px 0;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
}

.collections_container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.collections_inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 48px 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.collection_link {
  transition: opacity .2s ease;

  &:hover {
    opacity: 0.8;
  }
}

.collection {
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 2px;
  height: 300px;
  margin-bottom: 16px;
  overflow: hidden;

  div:nth-child(1) {
    grid-area: 1 / 1 / 3 / 3;
  }

  div:nth-child(2) {
    grid-area: 1 / 3 / 3 / 5;
  }

  div:nth-child(3) {
    grid-area: 3 / 1 / 5 / 3;
  }

  div:nth-child(4) {
    grid-area: 3 / 3 / 5 / 5;
  }
}

.image {
  span {
    width: 100%;
    height: 100%;
  }

  img {
    aspect-ratio: 4 / 3;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.collection_title {
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.collection_owner {
  color: #767676;
  margin: 13px 0;

  a {
    color: #767676;

    &:hover {
      color: #1d1d1f;
      text-decoration: underline;
    }
  }
}

.collections_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  a {
    line-height: 25px;
    color: #767676;
    background-color: #eee;
    border-radius: 2px;
    text-transform: capitalize;
    padding: 0 8px;
    transition: .3s ease;

    &:hover {
      color: #1d1d1f;
      background-color: #e1e1e1;
    }
  }
}