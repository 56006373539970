.tabs_wrapper {
  position: sticky;
  top: 60px;
  left: 0;
  z-index: 19;
}

.users_outer {
  padding: 25px 0;

  h1 {
    font-size: 48px;
    text-transform: capitalize;
    padding: 20px 0;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
}

.users_container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.users_inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  justify-content: center;
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.user {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  display: grid;
  row-gap: 16px;
  padding: 16px;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 16px;

  .photo {
    img {
      border-radius: 50%;
    }
  }
}

.info {
  display: grid;
  row-gap: 3px;

  .name {
    font-size: 18px;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 15px;
    }
  }
  .username {
    font-size: 15px;
    color: #767676;
  }
}

.body {
  display: flex;
  column-gap: 8px;

  .image {
    flex: 1;

    img {
      aspect-ratio: 4 / 3;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.link {
  font-size: 14px;
  color: #767676;
  border-width: 1px;
  border-style: solid;
  border-color: #d1d1d1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 11px;
  transition: all .3s ease;

  &:hover {
    color: #111;
    border-color: #111;
  }
}