.collection_bg {
  user-select: none;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: "";
    background: linear-gradient(#fffc, #ffffffe6 50%, #fff);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.collection_name {
  position: relative;
  padding: 50px 0 25px 0;

  h1 {
    font-size: 48px;

    @media (max-width: 768px) {
      font-size: 26px
    }
  }

  @media (max-width: 768px) {
    padding: 50px 12px 25px 12px;
  }
}